.da__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 6rem;
    background-color: var(--color-bg);
}

.sticky{
    box-shadow: 0px 5px 10px rgba(0,0, 0,0.6);
    position: sticky;
    top:0;
    z-index: 100;
}

.da__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.da__navbar-links_logo {
    margin-right: 2rem;
}

/* .da__navbar-links_logo img {
    height: 30px;
} */

.da__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.da__navbar-links_container p,
.da__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.da__navbar-menu {
    margin-left: 1rem;

    display: none;
    position: relative;
}

.da__navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    
    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0,0, 0,0.2);
}

.da__navbar-menu_container p {
    margin: 1rem 0;
}


/* @media screen and (max-width: 700px) {
    .da__navbar-links_container {
        display: none;
    }
    
    .da__navbar-menu
    {
        display: flex;
    }
} */

@media screen and (max-width: 750px) {
    .da__navbar {
        padding: 2rem;
    }

    .da__navbar-links_container {
        display: none;
    }
    
    .da__navbar-menu
    {
        display: flex;
    }
    .da__navbar-menu_container {
        top: 20px;
    }

    /* .da__navbar {
        padding: 1rem 2rem;
    }

    .da__navbar-links_logo {
        margin-right: 1rem;
    } */
}