.da__header-main{
    display: flex;
    flex-direction: column;
}

.da__header-main p{
    font-family: var(--font-family);
    font-weight: normal;
    font-style: normal;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);

    margin-top: 1.5rem;
}
.da__header {
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    align-items: flex-start;
}

.da__header-content {
    flex:1;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    margin-right: 0.1rem;
}

.da__header-content h1{
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 62px;
    line-height: 75px;
    letter-spacing: -0.04rem;
}

.da__header-content p{
    font-family: var(--font-family);
    font-weight: normal;
    font-style: normal;
    font-size: 20px;
    line-height: 27px;
    color: var(--color-text);
   
    margin-top: 1.5rem;
}

.da__header-image {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 50%;
    min-width: 200px;
}

.da__header-image img {
    width: 100%;
    height: auto;
}

@media screen and (max-width: 750px)
{
    .da__header{
        flex-direction: column;
    }

    .da__header h1{
        font-size: 48px;
        line-height: 60px;
    }

    .da__header p{
        font-size: 16px;
        line-height: 24px;
    }

    .da__header-main p{
        font-size: 16px;
        line-height: 24px;
    }

    .da__header-content{
        margin: 0 0 2rem;
        order: 2;
    }
    .da__header-image {
        max-width: 100%;
        order: 1;
    }
}