.da__display-none{
    display: none;
}

.da__product-container {
    display: flex;
    flex-direction: column;
 
    padding: 2rem;
    margin: 2rem 0;
    background: var(--color-footer);

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 30% -100%, #042c54 25%, rgba(4, 44, 84, 1) 85%, rgba(27, 120, 222, 1) 100%);

    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

    border-radius: 10px;
}

.da__product-header__container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;

    margin: 2rem 1rem 2rem 1rem;
}

.da__product-header__title {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: stretch;
    margin: 0 1rem 0 0;
    /* max-width: 900px; */
}


.da__product-header__media
{
    display: flex;
    flex: 1;
    padding: 1rem;
    max-width: 45%;
    min-height: 100%;
}

/* https://stackoverflow.com/questions/35814653/automatic-height-when-embedding-a-youtube-video */
.video-container {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	width: 100%;
}
.video-container iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
    border: 0;
}

.da__product-header__title h1{
    font-size: 36px;
    line-height: 45px;
    font-weight: 800;
    max-width: 510;
    font-family: var(--font-family);
}

.da__product-header__title p{
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #fff;
    white-space: pre-line;
    /* color: var(--color-text); */
}

.da__product-header__title a {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-text);
    margin-top: .6rem;
    align-self: flex-start;
}

@media screen and (max-width: 750px) {
    .da__product-container {
        padding: 1rem;
        margin: 1rem 0;
    }
    .da__product-header__container{
        flex-direction: column;
    }
}