.da__footer{
    display: flex;
    flex-wrap: none;
    flex-direction: row;
    align-items: center;
    /* align-content: space-around; */
}

.da__footer-logo {
    margin: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: -1;
}

/* .da__footer-logo img{
    width: 200px;
} */
.da__footer-logo p{
    font-family: var(--font-family);
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    margin-top: 0.1rem;
}

.da__footer-links {
    display: flex;
    flex: 5;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: space-between;
    justify-content: space-around;
    margin: 1rem 1rem;
}

.da__footer-links_item {
    display: flex;
    align-items: center;
    margin: .2rem 1rem;
}

.pointer {
    cursor: pointer;
}

.da__footer-links_item p{
    font-family: var(--font-family);
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    margin-left: 0.2rem;
}

.da__footer-copyright {
    margin: 1rem 1rem;
}

.da__footer-copyright p {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: .03rem;
    word-spacing: .15rem;
}

@media screen and (max-width:1200px) {
  
    .da__footer-links {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: center;
        margin: 1rem 1rem;
    }
    
}

